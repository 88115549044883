<template>
  <div class="cord-page">
    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
      <b-overlay :show="loading">
        <b-row>
          <b-col lg="12" sm="12">
            <body-card>
              <template v-slot:headerTitle>
                <h4 class="card-title"> {{ $t('approvalProcess.ex_garden_info') }}</h4>
              </template>
              <template v-slot:body>
                <b-row>
                  <b-col xl="12" lg="12" sm="12">
                    <b-form @submit.prevent="handleSubmit(register)" @reset.prevent="reset">
                      <b-row>
                        <b-col sm="12">
                          <table class="table table-sm table-bordered table-striped">
                            <tbody>
                              <tr>
                                <td width="60%">
                                  {{ $t('teaGarden.permission_for_tea_quantity_applied_kg') }}
                                </td>
                                <td>{{ $n(exGardenData.application_qty) }}</td>
                              </tr>
                              <tr>
                                <td>
                                  {{ $t('teaGarden.verified_qty') }}
                                </td>
                                <td>
                                  <ValidationProvider name="Verified Tea Quantity (Kg.)" vid="verified_qty" :rules="'required|max_value:' + exGardenData.application_qty">
                                    <b-form-group
                                      slot-scope="{ valid, errors }"
                                      label-for="verified_qty">
                                      <b-form-input
                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                        id="verified_qty"
                                        v-model="form.verified_qty"
                                        @input="updateFinalPayableCess()"
                                        @keypress="isNumber"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                      ></b-form-input>
                                      <div class="invalid-feedback">
                                        {{ errors[0] }}
                                      </div>
                                    </b-form-group>
                                  </ValidationProvider>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  {{ $t('teaGarden.average_auction_price_tk') }}
                                </td>
                                <td>{{ $n(exGardenData.avg_auction_price) }}</td>
                              </tr>
                              <tr>
                                <td>
                                  {{ $t('teaGarden.total_value_of_tea_quantity_applied_tk') }}
                                </td>
                                <td>{{ $n(exGardenData.total_value) }}</td>
                              </tr>
                              <tr>
                                <td>
                                  {{ $t('teaGarden.total_payable_cess_tk') }}
                                </td>
                                <td>{{ $n(exGardenData.total_payable_cess) }}</td>
                              </tr>
                              <tr>
                                <td>
                                  {{ $t('teaGarden.total_value_of_tea_quantity_verified_tk') }}
                                </td>
                                <td>{{ $n(form.total_verified_amount) }}</td>
                              </tr>
                              <tr>
                                <td>
                                  {{ $t('teaGarden.final_payable_cess_tk') }}
                                </td>
                                <td>{{ $n(form.final_payable_cess) }}</td>
                              </tr>
                              <tr>
                                <td>
                                  {{ $t('teaGarden.extra_cess_tk') }}
                                </td>
                                <td>{{ $n(exGardenData.total_payable_cess - form.final_payable_cess) }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </b-col>
                      </b-row>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ $t('approvalProcess.propose') }}</b-button>
                          &nbsp;
                          <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-5')">{{ $t('globalTrans.cancel') }}</b-button>
                        </div>
                      </div>
                    </b-form>
                  </b-col>
                </b-row>
              </template>
            </body-card>
          </b-col>
        </b-row>
      </b-overlay>
    </ValidationObserver>
  </div>
</template>
<script>
  import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
  import { applicantRecommendationExGarden } from '../../api/routes'
  import { helpers } from '@/utils/helper-functions'

  export default {
    name: 'FormLayout',
    props: ['id'],
    created () {
      if (this.id) {
        const tmp = this.getInfo()
        const tmp1 = tmp.recommendations[0]
        tmp1.taggable_id = tmp.taggable_id
        tmp1.verified_qty = ''
        tmp1.final_payable_cess = 0
        this.form = tmp1
        // this.form.user_id = this.$store.state.Auth.authUser.user_id
        // this.form.designation_id = this.$store.state.Auth.authUser.office_detail.designation_id
        // this.form.office_type_id = this.$store.state.Auth.authUser.office_detail.office_type_id
        // this.form.office_id = this.$store.state.Auth.authUser.office_detail.office_id
        this.exGardenInfo()
      }
    },
    watch: {
      currentLocale: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.localizeUserList(newVal)
        }
      }
    },
    computed: {
      loading () {
        return this.$store.state.commonObj.loading
      },
      currentLocale () {
        return this.$i18n.locale
      }
    },
    data () {
      return {
        form: {
          verified_qty: '',
          total_verified_amount: 0,
          final_payable_cess: 0,
          adv_cess_pay_amount: 0
        },
        exGardenData: {
          application_qty: 0
        },
        loader: false
      }
    },
    methods: {
      EngBangNum (n) {
        if (this.$i18n.locale === 'bn') {
          return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
        } else {
          return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
        }
      },
      async exGardenInfo () {
        const loadingState = { loading: true, listReload: false }
        this.$store.dispatch('mutateCommonProperties', loadingState)
        const result = await RestApi.getData(teaGardenServiceBaseUrl, '/btb/ex-garden-app/details/' + this.form.taggable_id)
        if (result.success) {
          this.exGardenData = result.data
          this.form.verified_qty = this.exGardenData.application_qty
          this.form.total_verified_amount = this.exGardenData.total_value
          this.form.final_payable_cess = this.exGardenData.total_payable_cess
          const loadingState = { loading: false, listReload: false }
          this.$store.dispatch('mutateCommonProperties', loadingState)
        }
      },
      getInfo () {
        const tmpData = this.$store.state.list.find(item => item.id === this.id)
        return JSON.parse(JSON.stringify(tmpData))
      },
      isNumber (evt) {
        helpers.isNumber(evt)
      },
      updateFinalPayableCess () {
        if (this.form.verified_qty > this.exGardenData.application_qty) {
          this.$nextTick(() => {
            this.form.verified_qty = this.exGardenData.application_qty
            this.form.total_verified_amount = this.exGardenData.avg_auction_price * this.form.verified_qty
            this.form.final_payable_cess = this.exGardenData.avg_auction_price * this.form.verified_qty / 100
          })
        } else {
          this.form.total_verified_amount = this.exGardenData.avg_auction_price * this.form.verified_qty
          this.form.final_payable_cess = this.exGardenData.avg_auction_price * this.form.verified_qty / 100
        }
      },
      async register () {
        const loadingState = { loading: true, listReload: false }
        this.$store.dispatch('mutateCommonProperties', loadingState)
        let result = null
        const loadinState = { loading: false, listReload: true }
        this.form.adv_cess_pay_amount = this.exGardenData.total_payable_cess - this.form.final_payable_cess
        result = await RestApi.putData(teaGardenServiceBaseUrl, `${applicantRecommendationExGarden}/${this.form.id}`, this.form)
        this.$store.dispatch('mutateCommonProperties', loadinState)
        if (result.success) {
          this.$store.dispatch('mutateCommonProperties', { loading: true })
          this.$toast.success({
            title: this.$t('globalTrans.success'),
            message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
          })
          this.$bvModal.hide('modal-5')
        } else {
          this.$refs.form.setErrors(result.errors)
        }
        this.loader = false
      }
    }
  }
</script>
